import BundleDetails from '../Details';
import combineMedia from '~/utils/combine-media';
import PDPWrapper from '~/components/Generic/PDPWrapper';

type Props = {
  bundle: Queries.BundlePageFragment | Queries.KitPageFragmentFragment;
  notifications: Queries.NotificationFragmentFragment[];
  collection: Queries.BundlePageFragment['collection'];

  productCollection: Queries.ProductCollectionFragment[];
};

const BundlePage = ({ bundle, notifications, collection, productCollection }: Props) => {
  const { mediaArrayWithVideos, mediaArrayWithThumbnails } = combineMedia(
    // @ts-ignore
    bundle?.mainImage,
    bundle?.images,
    bundle.videos
  );
  const { badge } = bundle;
  return (
    <PDPWrapper
      bundle={bundle}
      mediaArrayWithThumbnails={mediaArrayWithThumbnails}
      mediaArrayWithVideos={mediaArrayWithVideos}
      productCollection={productCollection}
      badge={badge}
    >
      <BundleDetails
        notifications={notifications}
        bundle={bundle}
        collectionName={collection?.[0]?.slug ?? ''}
        forwardSx={{
          '--horizontal-inset': ['2.4rem', 0],
          px: 'var(--horizontal-inset)',
          marginBottom: [null, 7, 0],
        }}
      />
    </PDPWrapper>
  );
};

export default BundlePage;
